<template>
    <div class="panel-inner-container">
        <panel class="bg-gray-200">
            <template #header>
                <div class="d-flex justify-content-between">
                    <h4 class="mb-0">Title</h4>
                    <div class="ml-auto mt-n3">
                        <router-link to="/">
                            <button class="btn btn-block btn-primary bg-custom-primary-dark">
                                Menu
                            </button>
                        </router-link>
                        <span class="font-10 text-gray-500">Not part of design</span>
                    </div>
                </div>
            </template>

            <div class="font-weight-semibold font-24">
                Legend Title
            </div>
            <div class="mt-3">
                <p class="text-secondary">
                    Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat
                </p>

                <div class="mt-3">
                    <form>
                        <div class="form-group mb-3" v-for="(n,index) in 4" :key="index">
                            <label :for="'inputText'+ index">Input Text</label>
                            <input :id="'inputText'+ index" class="form-control" placeholder="Text Input" type="text">
                        </div>

                        <div class="form-group">
                            <label for="inputTextarea3">Title</label>
                            <textarea id="inputTextarea3" class="form-control" placeholder="Text Input" rows="3"></textarea>
                        </div>
                    </form>
                </div>
            </div>

            <div class="btn btn-block btn-primary my-8px" >Confirm Button</div>

            <div class="btn btn-block btn-secondary my-2" >Cancel Button</div>

        </panel>
    </div>
</template>

<script>
    import Panel from "../../../src/components/Panel/Panel";

    export default {
        name: "ProfileOffCanvas",
        components: {
            Panel
        }
    }
</script>
